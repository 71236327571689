import type { JSX } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import styles from '~/components/SkeletonPlaceholder.module.scss';

interface SkeletonPlaceholderProps {
  inline?: boolean;
  size?: JSX.CSSProperties['inline-size'];
  children?: JSX.Element;
  class?: string;
  classList?: Record<string, boolean | undefined>;
}

export default function SkeletonPlaceholder(props: SkeletonPlaceholderProps) {
  const tag = () => (props.inline ? 'span' : 'div');

  return (
    <Dynamic
      component={tag()}
      class={props.class}
      classList={{ ...props.classList, [styles.placeholder]: true }}
      style={{ 'inline-size': props.size }}
      aria-hidden="true"
    >
      <span>{props.children}</span>
    </Dynamic>
  );
}

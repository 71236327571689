import { gql } from '~/__gql-generated__';
import { cache, createAsync, type RouteDefinition } from '@solidjs/router';
import { For } from 'solid-js';

import IndividualPostLink from '~/components/IndividualPostLink';
import Poll from '~/components/Poll';
import rootStyles from '~/components/Root.module.scss';
import Title from '~/components/Title';
import TopSongs from '~/components/TopSongs';
import TopTags from '~/components/TopTags';
import styles from '~/routes/index.module.scss';
import { client } from '~/utils/graphql';
import { renderFragment } from '~/utils/renderFragment';

const MAIN_PAGE = gql(`
  query MainPage {
    posts(
      where: { status: PUBLISHED, type: ARTICLE }
      options: { limit: 30, sort: [{ sticky: DESC }, { publishedAt: DESC }] }
    ) {
      id
      slug
      revisionsConnection(where: { edge: { status: CURRENT } }) {
        edges {
          node {
            title
            lead
            publishedAt
            cover {
              url
            }
            category {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
`);

const getMainPage = cache(async () => {
  'use server';

  const { data } = await client.query({ query: MAIN_PAGE });

  return {
    posts: data.posts.map(post => {
      const revision = post.revisionsConnection.edges[0].node;

      return {
        slug: post.slug,
        title: renderFragment(JSON.parse(revision.title ?? '{}'), true),
        lead: renderFragment(JSON.parse(revision.lead ?? '{}'), true),
        publishedAt: new Date(revision.publishedAt),
        category: revision.category,
        cover: revision.cover?.url ?? '',
      };
    }),
  };
}, 'homepage');

export const route = {
  preload: () => getMainPage(),
} satisfies RouteDefinition;

export default function Home() {
  const data = createAsync(() => getMainPage(), {
    initialValue: { posts: [] },
  });

  return (
    <main>
      <Title />
      <TopTags />
      <div classList={{ [rootStyles.container]: true, [styles.main]: true }}>
        <ul class={rootStyles['clean-list']}>
          <For each={data().posts}>
            {post => (
              <li>
                <IndividualPostLink
                  title={post.title}
                  lead={post.lead}
                  slug={post.slug ?? ''}
                  categoryTitle={post.category?.title ?? ''}
                  categorySlug={post.category?.slug ?? ''}
                  publishedAt={post.publishedAt}
                  cover={post.cover}
                  level="h3"
                />
              </li>
            )}
          </For>
        </ul>
        <Poll />
        <TopSongs />
      </div>
    </main>
  );
}
